import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueProgressiveImage from 'vue-progressive-image'

Vue.use(VueProgressiveImage,  {
  removePreview: true,
  scale: true
})
Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
